export interface ListHeaderProps {
  className?: string
}

export type ListHeaderStyleProps = Partial<ListHeaderProps>

export enum ColumnNames {
  DOC_TYPE = 'docType',
  RATE = 'rate',
  FIELDS = 'fields',
  RESOLUTION = 'resolution',
  CREATED_AT = 'created',
  UPDATED_AT = 'updated',
  VERSION = 'version'
}
