import React, { useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Content, Column, Spinner, Text } from '../../components'
import { State, ValidationRuleCreators } from '../../store'
import { MainRoutes } from '../../routes'
import { RulesList } from './rules-list'

import { useStyle } from './program-rules.styles'

export const ProgramRulesPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const classes = useStyle()
  const { programs, isLoaded } = useSelector((state: State) => ({
    ...state.validationRule
  }))

  useEffect(() => {
    dispatch(ValidationRuleCreators.getValidationProgramsRequest())
  }, [])

  const handleOnNavigate = useCallback((id: number) => {
    navigate(`${MainRoutes.PROGRAM_RULES}/${id}`)
  }, [])

  return (
    <Column fullWidth className={classes.container} justifyContent="flex-start">
      <Content className={classes.content}>
        {isLoaded ? (
          <Column fullWidth alignItems="flex-start">
            <Text text="Program Rules" tx="programRules.title" preset="h1" />
            <RulesList programs={programs} onClick={handleOnNavigate} />
          </Column>
        ) : (
          <Column fullFilledHeight>
            <Spinner />
          </Column>
        )}
      </Content>
    </Column>
  )
}
