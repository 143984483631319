import React, { useCallback, useState, useContext, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { DropdownItemData, useOutsideAlerter } from '@cse/ui'

import {
  Breadcrumbs,
  Column,
  Dropdown,
  Icon,
  Row,
  StatusBadge,
  Text,
  TextTranslate
} from '../../../components'
import { ApplicationSmall, SubmissionVersion } from '../../../services'
import { AppResolutionPicker } from '../../../views'
import { DocumentCreators } from '../../../store'
import { UserContext } from '../../../providers'
import { useSettings } from './hooks'

import { DocHeaderProps } from './doc-header.types'
import { useStyle } from './doc-header.styles'

export const DocHeader = ({
  data,
  catalog,
  isVersionChanged,
  defaultOpen,
  onOpen,
  onSubmit,
  onVersionChange
}: DocHeaderProps) => {
  const dispatch = useDispatch()
  const headerRef = useRef<HTMLDivElement>(null)

  const { isSysAdmin } = useContext(UserContext)
  const [open, changeOpen] = useState(Boolean(defaultOpen))
  const classes = useStyle({ open })

  const { id = '' } = useParams()
  const {
    title,
    resolution,
    rpsResolutionDt,
    rpsReviewer,
    submissionVersions
  } = data as ApplicationSmall
  const [openModal, changeOpenModal] = useState(false)
  const currentVersion = submissionVersions?.find(
    (ver: SubmissionVersion) => String(ver.id) === id
  )
  const [version, changeVersion] = useState(currentVersion?.id)

  const isResolutionPicker = data ? data.allowRpsResolutions : false

  const { backProps, modalDefaultValue, Data, Versions } = useSettings({
    id,
    data,
    catalog
  })

  const handleOnOpenModal = useCallback(
    (state: boolean) => () => {
      changeOpenModal(state)
    },
    [openModal]
  )

  const handleOnOpen = () => {
    changeOpen(!open)

    if (onOpen) {
      onOpen(!open)
    }
  }

  const handleOnChange = (item: DropdownItemData<TextTranslate>) => {
    const selectedId = Number(item.id)
    changeVersion(selectedId)
    onVersionChange(selectedId)

    dispatch(
      DocumentCreators.getDocumentHeaderRequest({
        params: { appId: String(selectedId) }
      })
    )
  }

  useOutsideAlerter(headerRef, isVersionChanged, () => onVersionChange())

  return (
    <Column fullWidth>
      <Row fullWidth justifyContent="space-between">
        <Row ref={headerRef}>
          <Breadcrumbs {...backProps} />
          <Text className={classes.title} text={String(title)} preset="h1" />
          <Dropdown
            disabled={Versions.length === 1}
            className={classes.versions}
            isScrollable
            isAlwaysSelected
            data={Versions}
            label="Version"
            preset="body"
            active={version}
            defaultValue={version}
            value={version}
            onChange={handleOnChange}
          />
          <Text
            className={classes.iqr}
            text="IQR Result:"
            tx="document.iqrResult"
            color="border"
            preset="caption"
          />
          <StatusBadge className={classes.badge} value={resolution} />
        </Row>
        <Row>
          {!isSysAdmin && (
            <Row>
              <AppResolutionPicker
                isAllowed={isResolutionPicker}
                rpsReviewer={rpsReviewer}
                date={rpsResolutionDt}
                defaultValue={modalDefaultValue}
                data={Data}
                onClose={handleOnOpenModal(false)}
                onSubmit={onSubmit}
              />
            </Row>
          )}
          <Row
            className={classes.subHeader}
            fullWidth
            justifyContent="space-between"
            onClick={handleOnOpen}
          >
            <Icon className={classes.arrow} name="ARROW_DROP_DOWN" />
          </Row>
        </Row>
      </Row>
    </Column>
  )
}
